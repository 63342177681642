import {useFragment, useLazyLoadQuery} from 'react-relay';
import cx from 'classnames';
import React, {useEffect, useLayoutEffect, useMemo, useReducer, useRef, useState} from 'react';
import { graphql } from "react-relay";
import { useNavigate, useParams } from 'react-router-dom';

import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faDownload, faPrint, faEraser } from '@fortawesome/pro-light-svg-icons';

import { DocumentScreenQuery } from './__generated__/DocumentScreenQuery.graphql';

import styles from './DocumentScreen.module.scss';
import useUpdateSignatoryDocumentStatus from 'mutations/updateSignatoryDocumentStatus';
import useContainerDimensions, { Dimensions } from 'hooks/useContainerDimensions';
import Button from 'components/Button/Button';
import useTranslate from 'hooks/useTranslate';
import DocumentApprovalActions from 'components/DocumentApprovalActions/DocumentApprovalActions';
import RejectSignatureOrderModal from 'screens/SignatoryViewerScreen/components/RejectSignatureOrderModal';
import Sentry from 'sentry';
import { DocumentScreen_DownloadButton_document$key } from './__generated__/DocumentScreen_DownloadButton_document.graphql';
import { DocumentScreen_XmlDocumentScreen_document$key } from './__generated__/DocumentScreen_XmlDocumentScreen_document.graphql';
import { DocumentScreen_XmlDocumentScreen_viewer$key } from './__generated__/DocumentScreen_XmlDocumentScreen_viewer.graphql';
import { DocumentScreen_PdfDocumentScreen_document$key } from './__generated__/DocumentScreen_PdfDocumentScreen_document.graphql';
import { DocumentScreen_PdfDocumentScreen_viewer$key } from './__generated__/DocumentScreen_PdfDocumentScreen_viewer.graphql';
import { useSignState, SignDocumentFormFieldInput, useDocumentState } from 'mutations/sign';
import { DocumentScreen_PdfDocumentForm_document$key } from './__generated__/DocumentScreen_PdfDocumentForm_document.graphql';
import { DocumentScreen_ResetButton_document$key } from './__generated__/DocumentScreen_ResetButton_document.graphql';

if (process.env.PDF_WORKER_SRC) {
  pdfjs.GlobalWorkerOptions.workerSrc = process.env.PDF_WORKER_SRC;
}

type Mode = 'sign' | 'download'
interface Props {
  mode: Mode
}

export default function DocumentScreen(props: Props) {
  const params = useParams<{documentId: string}>();
  const navigate = useNavigate();
  const documentsRef = useRef<HTMLDivElement>(null);

  const data = useLazyLoadQuery<DocumentScreenQuery>(
    graphql`
      query DocumentScreenQuery($id: ID!) {
        document(id: $id) {
          __typename
          id
          ... DocumentScreen_PdfDocumentScreen_document
          ... DocumentScreen_XmlDocumentScreen_document

          signatoryViewerStatus
        }

        viewer {
          ... DocumentScreen_PdfDocumentScreen_viewer
          ... DocumentScreen_XmlDocumentScreen_viewer
        }
      }
    `,
    {
      id: params.documentId!
    }
  );

  useEffect(() => {
    const keyboardHandler = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        navigate(-1);
      }
    };

    window.document.addEventListener("keydown", keyboardHandler, false);
    return () => window.document.removeEventListener("keydown", keyboardHandler, false);
  }, [navigate]);

  const handleClose = (event?: React.MouseEvent) => {
    event?.stopPropagation();
    navigate(-1);
  }

  const [openedExecutor, openedState] = useUpdateSignatoryDocumentStatus();

  useEffect(() => {
    if (!data.document) return;
    if (openedState.pending) return;
    if (data.document.signatoryViewerStatus === null) {
      openedExecutor.execute({
        input: {
          documentId: data.document.id,
          status: 'OPENED'
        }
      });
    }
  }, [openedExecutor, data.document, openedState.pending]);

  const {viewer, document} = data;
  if (!document) return null;
  if (!viewer) return null;

  if (document.__typename === 'XmlDocument') {
    return (
      <div className={styles['document-screen']} data-testid="document-screen">
        <XmlDocumentScreen
          document={document}
          viewer={viewer}

          mode={props.mode}
          onClose={handleClose}
        />
      </div>
    )
  }
  if (document.__typename === 'PdfDocument') {
    return (
      <div className={styles['document-screen']} data-testid="document-screen">
        <PdfDocumentScreen
          document={document}
          viewer={viewer}

          mode={props.mode}
          onClose={handleClose}
        />
      </div>
    );
  }

  return (
    <div className={styles['document-screen']} data-testid="document-screen">
      <div className={styles.documents} ref={documentsRef}>
        Unsupported document
      </div>
      <div className={styles['top-actions']}>
        <div className={styles.touch} onClick={handleClose}>
          <div className={styles.action}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
      </div>
    </div>
  );
}

type DocumentProxy = Parameters<NonNullable<React.ComponentProps<typeof Document>["onLoadSuccess"]>>[0]
function PdfDocumentScreen({onClose, ...props}: {
  mode: Mode
  document: DocumentScreen_PdfDocumentScreen_document$key
  viewer: DocumentScreen_PdfDocumentScreen_viewer$key

  onClose: () => void
}) {
  const document = useFragment(
    graphql`
      fragment DocumentScreen_PdfDocumentScreen_document on PdfDocument {
        id
        title
        blob
        signatoryViewerStatus

        form {
          enabled
        }
        
        ... DocumentScreen_PdfDocumentForm_document
        ... DocumentApprovalActions_document
        ... DocumentScreen_DownloadButton_document
        ... DocumentScreen_ResetButton_document
      }
    `,
    props.document
  );
  const viewer = useFragment(
    graphql`
      fragment DocumentScreen_PdfDocumentScreen_viewer on SignatoryViewer {
        ui {
          ... DocumentApprovalActions_ui
          renderPdfAnnotationLayer
        }

        documents {
          edges {
            status
          }
        }
      }
    `,
    props.viewer
  );

  const [documentProxy, setDocumentProxy] = useState<DocumentProxy | null>(null);
  const blobUrl = useBlobUrl(document.blob, 'application/pdf');
  const translate = useTranslate();
  const navigate = useNavigate();
  const [showRejectConfirm, toggleRejectConfirm] = useReducer(show => !show, false);
  const singleDocument = viewer.documents.edges.length === 1;
  const documentsRef = useRef<HTMLDivElement | null>(null);
  const [renderKey, setRenderKey] = useState(() => Math.random().toString());
  
  const handleApprove = () => {
    if (singleDocument) {
      navigate('/sign');
    } else {
      onClose();
    }
  }

  const handleReject = () => {
    if (singleDocument) {
      toggleRejectConfirm();
    } else {
      onClose();
    }
  }

  const handleReset = () => {
    setRenderKey(Math.random().toString());
  }

  const dimensions = useContainerDimensions(documentsRef, true);
  const renderForms = props.mode === 'sign' && document.form?.enabled ? true :  false;
  const renderAnnotationLayer = renderForms || viewer.ui.renderPdfAnnotationLayer;
  const [formValid, setValid] = useState(renderForms ? false : true);
  const [formReady, setFormReady] = useState(false);

  const onLoadSuccess = async (proxy: DocumentProxy) => {
    setDocumentProxy(proxy);
  }

  const documents = (
    <Document
      file={`data:application/pdf;base64,${document.blob}`}
      onLoadSuccess={onLoadSuccess}
      loading={<div className={styles.loader}><h1>{translate('Loading PDF')}</h1>...</div>}
      onLoadError={error => {
        console.error(error);
        Sentry.captureException(error);
      }}
      onSourceError={error => {
        console.error(error);
        Sentry.captureException(error);
      }}
      
    >
      {Array.from(
        new Array(documentProxy?.numPages ?? 0),
        (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={(dimensions.width || 300) - 30 /* Margin */ - 15 /* Scroll bar */}
            renderTextLayer={false}
            renderAnnotationLayer={renderAnnotationLayer}
            renderForms={renderForms}

            onRenderAnnotationLayerSuccess={() => setFormReady(true)}

            onClick={event => {
              if (event?.target && "tagName" in event.target) {
                if (event.target.tagName.toLowerCase() === 'a' && event.target.href) {
                  event.preventDefault();
                  window.open(event.target.href, '_blank');
                }
              }
            }}
          />
        )
      )}
    </Document>
  );

  return (
    <>
      <div
        className={styles.documents}
        ref={documentsRef}
      >
        {(renderForms && documentProxy) ? (
          <PdfDocumentForm
            key={renderKey}
            onValid={setValid}
            document={document}
            documentProxy={documentProxy}
            ready={formReady}
          >
            {documents}
          </PdfDocumentForm>
        ) : documents}
      </div>
      <div className={styles['top-actions']}>
        {!singleDocument ? (
          <div className={styles.touch} onClick={onClose}>
            <div className={styles.action}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
        ) : <div />}
        <div className="d-flex">
          {renderForms ? (
            <ResetButton
              onClick={handleReset}
              document={document}
            />
          ) : null}
          {blobUrl ? <DownloadButton document={document} blobUrl={blobUrl} /> : null}
          {blobUrl ? <PrintButton blobUrl={blobUrl} /> : null}
        </div>
      </div>
      {props.mode === 'sign' ? (
        <div className={cx(styles['bottom-actions'], styles.open)}>
          {document.signatoryViewerStatus === null ? null : document.signatoryViewerStatus === 'PREAPPROVED' ? (
            <Button variant="primary" onClick={onClose}>Close</Button>
          ) : (
            <DocumentApprovalActions
              document={document}
              ui={viewer.ui}
              onApproved={handleApprove}
              onRejected={handleReject}
              approveText={singleDocument ? translate('Approve & sign') : undefined}
              approveDisabled={!formValid}
            />
          )}
        </div>
      ) : null}
      {props.mode === 'sign' && singleDocument ? (
        <RejectSignatureOrderModal show={showRejectConfirm} onCancel={toggleRejectConfirm} onReject={onClose} />
      ) : null}
    </>
  );
}

type FieldObject = {
  id: string
  type: string
  exportValues?: string
  editable?: boolean
}

function PdfDocumentForm(props: {
  children: React.ReactNode,
  onValid: (valid: boolean) => void,
  document: DocumentScreen_PdfDocumentForm_document$key
  documentProxy: DocumentProxy
  ready: boolean
}) {
  const document = useFragment(
    graphql`
      fragment DocumentScreen_PdfDocumentForm_document on PdfDocument {
        id
      }
    `,
    props.document
  );

  const documentProxy = props.documentProxy;
  const [documentState, setDocumentState] = useDocumentState(document.id);
  const formRef = useRef<HTMLFormElement | null>(null);

  useLayoutEffect(() => {
    if (!formRef.current) return;

    const changeListener = async () => {
      props.onValid(formRef.current!.checkValidity());
      
      /** Convert values */
      const fieldMap : {[key: string]: FieldObject[]} = (await documentProxy.getFieldObjects() as any) ?? {};
      const annotationStorage : {[key: string]: {value: string | boolean}} = (documentProxy.annotationStorage.getAll() as any) ?? {};
      const values = Object.keys(fieldMap).map(name => {
        const fields = fieldMap[name].filter(f => f.editable);

        // TextField / ComboBox / CheckBox
        if (fields.length === 1) {
          const field = fields[0];
          const selected = annotationStorage[field.id];
          if (!selected) return null;

          return {
            field: name,
            value: field.type === 'checkbox' ? (selected.value ? 'Yes' : 'Off') : selected.value
          }
        }
        // Group / Radio
        const selected = fields.find(s => annotationStorage[s.id]?.value === true);
        if (!selected) return null;
        return {
          field: name,
          value: selected.exportValues
        }
      }).filter((f): f is SignDocumentFormFieldInput => f?.value ? true : false);
      
      /** Save document values */
      setDocumentState({
        form: {
          fields: values
        }
      });
    };
    formRef.current.addEventListener('change', changeListener);
    return () => formRef.current?.removeEventListener('change', changeListener);
  }, [document, props.onValid, documentProxy]);

  useLayoutEffect(() => {
    if (!props.ready) return;
    let isSubscribed = true;
    (async () => {
      await new Promise<void>(resolve => resolve());
      if (!isSubscribed) return;
      const fieldMap : {[key: string]: FieldObject[]} = (await documentProxy.getFieldObjects() as any) ?? {};
      if (!isSubscribed) return;
      const annotationStorage = documentProxy.annotationStorage;

      documentState?.form?.fields.forEach(({field: name, value}) => {
        const fields = fieldMap[name];

        // TextField / ComboBox / CheckBox
        if (fields.length === 1) {
          const field = fields[0];
          const elem = formRef.current!.querySelector(`[data-element-id="${field.id}"]`) as HTMLInputElement | HTMLSelectElement;
          if (!elem) return;

          if (field.type === 'checkbox') {
            annotationStorage.setValue(field.id, {
              value: (value === 'Yes' ? true : false)
            });
            if (value === 'Yes') elem.setAttribute('checked', 'checked');
            else elem.removeAttribute('checked');
          } else {
            annotationStorage.setValue(field.id, {
              value: value
            });
            elem.value = value;
          }

          elem.dispatchEvent(new Event('change'));
          return;
        }

        // Group / Radio
        fields.forEach(field => {
          const elem = formRef.current!.querySelector(`[data-element-id="${field.id}"]`) as HTMLInputElement;
          if (!elem) return;

          const checked = value === field.exportValues;
          annotationStorage.setValue(field.id, {
            value: checked ? true : false
          });
          if (checked) elem.setAttribute('checked', 'checked');
          else elem.removeAttribute('checked');

          elem.dispatchEvent(new Event('change'));
        });
      });
      
      formRef.current!.dispatchEvent(new Event('change'));
    })().catch(console.error.bind(console));

    return () => {
      isSubscribed = false;
    }
  }, [props.ready, props.onValid, documentProxy]);

  return (
    <form 
      ref={formRef}
      onSubmit={event => event.preventDefault()}
    >
      {props.children}
    </form>
  );
}

function XmlDocumentScreen({onClose, ...props}: {
  mode: Mode
  document: DocumentScreen_XmlDocumentScreen_document$key
  viewer: DocumentScreen_XmlDocumentScreen_viewer$key

  onClose: () => void
}) {
  const document = useFragment(
    graphql`
      fragment DocumentScreen_XmlDocumentScreen_document on XmlDocument {
        title
        blob
        signatoryViewerStatus

        ... DocumentApprovalActions_document
        ... DocumentScreen_DownloadButton_document
      }
    `,
    props.document
  );
  const viewer = useFragment(
    graphql`
      fragment DocumentScreen_XmlDocumentScreen_viewer on SignatoryViewer {
        ui {
          ... DocumentApprovalActions_ui
        }

        documents {
          edges {
            status
          }
        }
      }
    `,
    props.viewer
  );

  const blobUrl = useBlobUrl(document.blob, 'text/xml');
  const translate = useTranslate();
  const navigate = useNavigate();
  const [showRejectConfirm, toggleRejectConfirm] = useReducer(show => !show, false);
  const singleDocument = viewer.documents.edges.length === 1;
  
  const handleApprove = () => {
    if (singleDocument) {
      navigate('/sign');
    } else {
      onClose();
    }
  }

  const handleReject = () => {
    if (singleDocument) {
      toggleRejectConfirm();
    } else {
      onClose();
    }
  }

  return (
    <>
      <div className={styles.documents} >
        <div className={styles.xmlDocument}>
          <pre>
            <code>{atob(document.blob!)}</code>
          </pre>
        </div>
      </div>
      <div className={styles['top-actions']}>
        {!singleDocument ? (
          <div className={styles.touch} onClick={onClose}>
            <div className={styles.action}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
        ) : <div />}
        <div className="d-flex">
          {blobUrl ? <DownloadButton document={document} blobUrl={blobUrl} /> : null}
        </div>
      </div>
      {props.mode === 'sign' ? (
        <div className={cx(styles['bottom-actions'], styles.open)}>
          {document.signatoryViewerStatus === null ? null : document.signatoryViewerStatus === 'PREAPPROVED' ? (
            <Button variant="primary" onClick={onClose}>Close</Button>
          ) : (
            <DocumentApprovalActions
              document={document}
              ui={viewer.ui}
              onApproved={handleApprove}
              onRejected={handleReject}
              approveText={singleDocument ? translate('Approve & sign') : undefined}
            />
          )}
        </div>
      ) : null}
      {props.mode === 'sign' && singleDocument ? (
        <RejectSignatureOrderModal show={showRejectConfirm} onCancel={toggleRejectConfirm} onReject={onClose} />
      ) : null}
    </>
  );
}

function ResetButton(props: {
  document: DocumentScreen_ResetButton_document$key
  onClick: () => void
}) {
  const document = useFragment(
    graphql`
      fragment DocumentScreen_ResetButton_document on PdfDocument {
        id
      }
    `,
    props.document
  );
  const [, setDocumentState] = useDocumentState(document.id);

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setDocumentState(null);
    props.onClick();
  }

  return (
    <a className={styles.touch} href="#" onClick={handleClick} title="Reset form">
      <div className={styles.action}>
        <FontAwesomeIcon icon={faEraser} />
      </div>
    </a>
  )
}

function DownloadButton(props: {
  blobUrl: string
  document: DocumentScreen_DownloadButton_document$key
}) {
  const document = useFragment(
    graphql`
      fragment DocumentScreen_DownloadButton_document on Document {
        title
      }
    `,
    props.document
  );

  return (
    <a className={styles.touch} href={props.blobUrl} download={document.title} onClick={event => event.stopPropagation()} title="Download document">
      <div className={styles.action}>
        <FontAwesomeIcon icon={faDownload} />
      </div>
    </a>
  )
}

function PrintButton({blobUrl}: {
  blobUrl: string
}) {
  return (
    <a className={styles.touch} href={blobUrl} target="_blank" rel="noreferrer" onClick={event => event.stopPropagation()} title="Print document">
      <div className={styles.action}>
        <FontAwesomeIcon icon={faPrint} />
      </div>
    </a>
  );
}

function useBlobUrl(b64blob: string | null, mimeType: 'text/xml' | 'application/pdf') {
  return useMemo(() => {
    if (!b64blob) return;
    const blob = b64toBlob(b64blob, mimeType);
    const blobUrl = URL.createObjectURL(blob);
    return blobUrl;
  }, [b64blob]);
}

const b64toBlob = (b64Data: string, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}