/**
 * @generated SignedSource<<a69bd56fedd3c8f66594de95474fcb26>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SignatoryDocumentStatus = "APPROVED" | "OPENED" | "PREAPPROVED" | "REJECTED" | "SIGNED" | "%future added value";
export type DocumentScreenQuery$variables = {
  id: string;
};
export type DocumentScreenQuery$data = {
  readonly document: {
    readonly __typename: string;
    readonly id: string;
    readonly signatoryViewerStatus: SignatoryDocumentStatus | null;
    readonly " $fragmentSpreads": FragmentRefs<"DocumentScreen_PdfDocumentScreen_document" | "DocumentScreen_XmlDocumentScreen_document">;
  } | null;
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"DocumentScreen_PdfDocumentScreen_viewer" | "DocumentScreen_XmlDocumentScreen_viewer">;
  };
};
export type DocumentScreenQuery = {
  response: DocumentScreenQuery$data;
  variables: DocumentScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "signatoryViewerStatus",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blob",
  "storageKey": null
},
v7 = {
  "kind": "TypeDiscriminator",
  "abstractKey": "__isDocument"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DocumentScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "document",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DocumentScreen_PdfDocumentScreen_document"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DocumentScreen_XmlDocumentScreen_document"
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DocumentScreen_PdfDocumentScreen_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DocumentScreen_XmlDocumentScreen_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DocumentScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "document",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PdfDocumentForm",
                "kind": "LinkedField",
                "name": "form",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "enabled",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "type": "PdfDocument",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "type": "XmlDocument",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SignatureOrderUI",
                "kind": "LinkedField",
                "name": "ui",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "disableRejection",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "renderPdfAnnotationLayer",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SignatoryDocumentConnection",
                "kind": "LinkedField",
                "name": "documents",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SignatoryDocumentEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "SignatoryViewer",
            "abstractKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8cf25206f3cb74560ecc59b16ef00195",
    "id": null,
    "metadata": {},
    "name": "DocumentScreenQuery",
    "operationKind": "query",
    "text": "query DocumentScreenQuery(\n  $id: ID!\n) {\n  document(id: $id) {\n    __typename\n    id\n    ...DocumentScreen_PdfDocumentScreen_document\n    ...DocumentScreen_XmlDocumentScreen_document\n    signatoryViewerStatus\n  }\n  viewer {\n    __typename\n    ...DocumentScreen_PdfDocumentScreen_viewer\n    ...DocumentScreen_XmlDocumentScreen_viewer\n    id\n  }\n}\n\nfragment DocumentApprovalActions_document on Document {\n  __isDocument: __typename\n  id\n  blob\n  title\n}\n\nfragment DocumentApprovalActions_ui on SignatureOrderUI {\n  disableRejection\n}\n\nfragment DocumentScreen_DownloadButton_document on Document {\n  __isDocument: __typename\n  title\n}\n\nfragment DocumentScreen_PdfDocumentForm_document on PdfDocument {\n  id\n}\n\nfragment DocumentScreen_PdfDocumentScreen_document on PdfDocument {\n  id\n  title\n  blob\n  signatoryViewerStatus\n  form {\n    enabled\n  }\n  ...DocumentScreen_PdfDocumentForm_document\n  ...DocumentApprovalActions_document\n  ...DocumentScreen_DownloadButton_document\n  ...DocumentScreen_ResetButton_document\n}\n\nfragment DocumentScreen_PdfDocumentScreen_viewer on SignatoryViewer {\n  ui {\n    ...DocumentApprovalActions_ui\n    renderPdfAnnotationLayer\n  }\n  documents {\n    edges {\n      status\n    }\n  }\n}\n\nfragment DocumentScreen_ResetButton_document on PdfDocument {\n  id\n}\n\nfragment DocumentScreen_XmlDocumentScreen_document on XmlDocument {\n  title\n  blob\n  signatoryViewerStatus\n  ...DocumentApprovalActions_document\n  ...DocumentScreen_DownloadButton_document\n}\n\nfragment DocumentScreen_XmlDocumentScreen_viewer on SignatoryViewer {\n  ui {\n    ...DocumentApprovalActions_ui\n  }\n  documents {\n    edges {\n      status\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "04034915276c1786ad752cd75ccc058c";

export default node;
