import React, { useState, FormEvent, useRef, useCallback, useEffect, useMemo } from 'react';
import { useFragment } from "react-relay";
import { graphql } from "react-relay";
import cx from 'classnames';

import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-regular-svg-icons';

import styles from './DrawableEvidenceProviderScreen.module.scss';
import turnScreen from './assets/turn-screen.png';
import { DrawableEvidenceProviderScreen$key } from './__generated__/DrawableEvidenceProviderScreen.graphql';
import Button from '../../../../components/Button/Button';
import useTranslate from '../../../../hooks/useTranslate';

import ThumbsUpIcon from '../../../../components/ThumbsUpIcon/ThumbsUpIcon';
import DrawableCanvas, {imageDataUtils} from '../../../../components/DrawableCanvas/DrawableCanvas';
import { throttle } from 'lodash';

export type ImageEvidence = {
  type: "drawable"
  id: string
  image: string
}
export type NameEvidence = ImageEvidence & {
  id: string
  name: string
}
export type DrawableEvidence = NameEvidence | ImageEvidence;

type Step = 'draw' | 'name' | 'review';

interface Props {
  id: string,
  requireName: boolean,
  minimumWidth: number | null,
  minimumHeight: number | null,
  onCancel?: () => void,
  onEvidence: (evidence: DrawableEvidence) => void,
  className?: string
}
export function DrawableEvidenceProviderScreen(props: Props) {
  const canvasWrapperRef = useRef<HTMLDivElement>(null);
  const {onCancel, onEvidence, requireName, minimumWidth, minimumHeight} = props;
  const [eraseKey, setEraseKey] = useState(1);
  const [hasDrawn, setHasDrawn] = useState<ImageData | null>();
  const [satisfiedMinimumArea, setSatisfyMinimumArea] = useState(!(minimumWidth || minimumHeight)); // if either minimum width or height is set, set initial state as false
  const [image, setImage] = useState<string | null>(null);
  const [step, setStep] = useState<Step>('draw');
  const translate = useTranslate();
  const [name, setName] = useState('');

  const [showSubmitTooltip, setButtonTooltip] = useState(false);

  const handleMinimumArea = useMemo(() => 
    throttle(
      (image: ImageData) => {
        if (!(minimumWidth || minimumHeight)) return;
    
        const boundingBox = imageDataUtils.boundingBox(image);
        if (!boundingBox) return;
    
        // assert that the drawed image is within the minimum defined boundaries, if provided
        const satisfyMinimumWidth = minimumWidth && boundingBox.width >= minimumWidth ? true : false;
        const satisfyMinimumHeight = minimumHeight && boundingBox.height >= minimumHeight ? true : false;
        const satisfiedMinimumArea = satisfyMinimumWidth && satisfyMinimumHeight;
        setSatisfyMinimumArea(satisfiedMinimumArea);
      },
      350,
      {leading: true, trailing: true}
    ),
    [
      setSatisfyMinimumArea,
      minimumHeight,
      minimumWidth
    ]
  );

  useEffect(() => {
    if (hasDrawn) handleMinimumArea(hasDrawn);
  }, [hasDrawn, handleMinimumArea]);

  const handleDraw = useCallback((image: ImageData) => {
    setHasDrawn(image);
  }, [setHasDrawn]);

  const handleErase = () => {
    setHasDrawn(null);
    setImage(null);
    setEraseKey(eraseKey => eraseKey + 1);
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (step === 'draw') {
      if (!hasDrawn) return;
      const cropped = imageDataUtils.crop(hasDrawn);
      if (!cropped) return;
      setImage(imageDataUtils.base64(cropped));
      setStep(requireName ? 'name' : 'review');
      return;
    } else if (step === 'name') {
      setStep('review');
      return;
    } else if (step === 'review') {
      onEvidence(!!name ? {type: 'drawable', id: props.id, image: image!, name} : {type: 'drawable', id: props.id, image: image!});
      return;
    }

    assertUnreachableStep(step);
  }

  const handleEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.keyCode === 13 || event.keyCode === 9) {
      (event.target as HTMLInputElement).blur();
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  }

  const disabled = (step === 'draw' && (!hasDrawn || !satisfiedMinimumArea)) || (step === 'name' && !name);
  const handleClick = (event: React.MouseEvent) => {
    if (disabled) {
      event.stopPropagation();
      setButtonTooltip(true);
    }
  }

  const handleBack = () => {
    if (step === 'draw') {
      if (onCancel) onCancel();
      return;
    }
    if (step === 'review') {
      if (requireName) setStep('name');
      else {
        setStep('draw');
      }
      return;
    }
    if (step === 'name') {
      setStep('draw');
      return;
    }
    assertUnreachableStep(step);
  }

  const canBack = onCancel || step !== 'draw';
  return (
    <div className={cx(styles['full-size'], props.className)}>
      <div className={styles['is-portrait']}>
        <img src={turnScreen} alt="Turn your phone 90 degrees into landscape mode" />
        <h1>{translate('Turn your phone horizontal')}</h1>
        <p>
          {translate('(Having issues? Check if your phone has auto-rotation enabled...)')}
        </p>
      </div>
      <form className={styles['is-landscape']} onSubmit={handleSubmit}>
        <div className={styles.container}>
          {step === 'draw' ? (
            <div className={styles.canvas} data-testid="drawable_canvas" ref={canvasWrapperRef}>
              <Button className={styles.eraseButton} variant="danger" icon={faTrash} onClick={handleErase} disabled={!hasDrawn} />
              {/* Since we will end up cropping the picture anyways we can use a massive centered canvas to account for multiple screensizes and resizing without losing the drawn material*/}
              <DrawableCanvas key={eraseKey} onDraw={handleDraw} initialDrawing={image || undefined} />
              {!hasDrawn && (
                <div className={styles.placeholder}>
                  {translate('Draw your signature here')}
                </div>
              )}
            </div>
          ) : step === 'name' ? (
            <div className={cx(styles.content, styles.name)}>
              <label htmlFor="drawable_name_input">
                {translate('Type in your full name')}
              </label>
              <input
                type="text"
                id="drawable_name_input"
                name="name"
                autoComplete="name"
                value={name}
                onChange={(event) => setName(event.target.value)}
                onKeyDown={handleEnterKey}
                onKeyUp={handleEnterKey}
                required={requireName}
                placeholder={translate('Type in your full name')}
              />
            </div>
          ) : step === 'review' ? (
            <div className={cx(styles.content, styles.review)}>
              <label>{translate('Approve your signature')}</label>
              <img src={`data:image/png;base64,${image}`} alt="" />
              {name && <p>{name}</p>}
            </div>
          ) : assertUnreachableStep(step)}
         
          <div className={styles.footer}>
            {canBack ? (
              <Button variant="transparent-bordered" icon={faAngleLeft} onClick={handleBack} className={styles.button}>
                {translate('Back')}
              </Button>
            ) : <div />}
            {/* <div className={styles.name}>
              <label htmlFor="drawable_name_input">
                {translate('Full name')}:
              </label>
              <input
                type="text"
                id="drawable_name_input"
                value={name}
                onChange={(event) => setName(event.target.value)}
                onKeyDown={handleEnterKey}
                onKeyUp={handleEnterKey}
                required={requireName}
                placeholder={translate('Type in your full name')}
              />
            </div>
             */}
            <div className={styles['tooltip-wrapper']} onMouseEnter={() => setButtonTooltip(true)} onMouseLeave={() => setButtonTooltip(false)} onClick={handleClick}>
              {disabled && showSubmitTooltip ? (
                <div className={styles.tooltip}>
                  {
                    step === 'draw'
                    ? satisfiedMinimumArea
                      ? translate('You must draw your signature before submitting')
                      : translate('You must draw a larger signature before submitting')
                    : step === 'name'
                      ? translate('You must enter your full name before submitting')
                      : null
                  }
                </div>
              ) : null}
              <Button
                data-testid="drawable_approve"
                type="submit"
                variant="primary"
                iconElement={step === 'review' ? <ThumbsUpIcon /> : undefined}
                icon={step !== 'review' ? faAngleRight : undefined}
                iconPosition="right"
                disabled={disabled}
                className={styles.button}
              >
                {step === 'review' ? translate('Approve') : translate('Next')}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

type RelayProps = Omit<Props, 'requireName' | 'id' | 'minimumWidth' | 'minimumHeight'> & {
  evidenceProvider: DrawableEvidenceProviderScreen$key
}
export default function RelayContainer(props: RelayProps) {
  const evidenceProvider = useFragment(
    graphql`
      fragment DrawableEvidenceProviderScreen on DrawableSignatureEvidenceProvider {
        id
        requireName
        minimumWidth
        minimumHeight
      }
    `,
    props.evidenceProvider
  );

  return <DrawableEvidenceProviderScreen {...props} id={evidenceProvider.id} requireName={evidenceProvider.requireName} minimumWidth={evidenceProvider.minimumWidth} minimumHeight={evidenceProvider.minimumHeight} />;
}

export function assertUnreachableStep(x: never): never {
  throw new Error(`Unexpected drawable step: ${x}`);
}